<template>
    <div class="resDialog">
        <el-dialog :visible="resVisible"  @close="$emit('update:resVisible',false)">
            <!-- <img :src="resResult? require('@/assets/images/gantt/success.png'):require('@/assets/images/gantt/error.png')" alt="111"> -->
            <img :src="iconImg" alt="">
            <div class="messyncTitle">{{resText}}</div>
            <el-button v-if="!resResult" type="primary" @click="$emit('btnClick') ">
                {{btnText}}
            </el-button>

        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        resVisible:Boolean,
        resResult:Boolean,
        resText:String,
        btnText:String,
        iconType:String,

    },
    computed:{
        iconImg(){
            if(this.iconType=='success'){
                return require('@/assets/images/gantt/success.png')
            }else if(this.iconType=="error"){
                return require('@/assets/images/gantt/error.png')
            }else{
                return require('@/assets/images/gantt/warning.png')
            }
        }
    }

}
</script>

<style lang="scss" scoped>
//mes同步弹框样式
.resDialog{
    position: relative;
    ::v-deep .el-dialog {
        width: 561px;
        height: 352px;
        background: #232323;
        box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.47);
        border-radius: 8px;
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
            font-size: 30px;
        }
    }
    .messyncTitle{
        font-size: 24px;
        font-family: HarmonyOS;
        font-weight: bold;
        margin: 20px 0px;
    }
    .el-button{
        width: 90px;
        height: 40px;
        background: #1BCE62;
        border-radius: 6px;
        font-size: 16px;
        font-family: HarmonyOS;
        font-weight: 400;
        color: #FFFFFF;
        padding: 0px;
    }
    img{width: 70ox;height: 70px;}
        
}
</style>