<template>
  <div class="ganttview-wrapper">
    <div id="container"></div>
    <div class="blackBorder"></div>
    <div class="blackBorder2"></div>
    <div id="filterStyle"></div>
    <svg class="svgClass">
      <filter id="inset-shadow">
        <!-- 投影偏移 -->
        <feOffset dx="0" dy="0" />
        <!-- 投影模糊 -->
        <feGaussianBlur stdDeviation="7" result="offset-blur" />
        <!-- 反转投影使其变成内投影 -->
        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse" />
        <!-- 内投影附加色 -->
        <feFlood flood-color="#fff" flood-opacity="1" result="color" />
        <feComposite operator="in" in="color" in2="inverse" result="shadow" />
        <!-- 把内投影显示在图像上 -->
        <feComposite operator="over" in="shadow" in2="SourceGraphic" />
      </filter>
    </svg>
  </div>
</template>

<script>
import dayjs from "dayjs";
import * as Highcharts from "highcharts/highcharts-gantt.js";
import * as Exporting from "highcharts/modules/exporting.js";
import * as draggable from "highcharts/modules/draggable-points.js";
import * as stock from "highcharts/modules/stock.js";
import * as treegrid from "highcharts/modules/treegrid.js";
// import * as accessibility from "highcharts/modules/accessibility.js";
import * as darkUnica from "highcharts/themes/dark-unica.js";
import HighchartsZHCN from "/public/highchart/highcharts-zh_CN";

Exporting(Highcharts);
draggable(Highcharts);
darkUnica(Highcharts);
stock(Highcharts);
treegrid(Highcharts);
// accessibility(Highcharts);
HighchartsZHCN(Highcharts);

export default {
  props: {
    ganttData: [],
    updateData: [],
    scheduledInfo: {},
    taskColor: {},
    ganttColumns: [],
    columnTexts: [],
    dragDrop: {},
    curStep: Number,
    stepLength: Number,
    tempSerialNum: String,
  },
  data() {
    return {
      curData: [],
      curTickInterval:1,//1-小时，2-天，3-月
      gantt: undefined,
      clickCount: 0,
      tickNum: 0,
      maxY: 10,
      clickTimer: 0,
      draging: false,

      yScrollMin: 0,
      yScrollMax: 0,
      xScrollMin: 0,
      xScrollMax: 0,
      filterData: [],
      marginLeft: 270,

      isFirst:false,
      isToday:true,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("resize", this.beforeInitGantt);
    // 监听全屏事件
// document.addEventListener('fullscreenchange', this.beforeInitGantt);
// document.addEventListener('webkitfullscreenchange', this.beforeInitGantt);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.beforeInitGantt);
  },

  computed: {},
  watch: {
    ganttData() {
      console.log('ganttData赋值初始',this.ganttData);
      this.beforeInitGantt();
    },
    updateData() {
      this.filterData = this.updateData;
      console.log('updateData赋值后过滤');
      this.filterGantt();
      this.updateGantt();
    },
  },
  methods: {
    //初始化之前的准备
    beforeInitGantt() {
      // console.log('beforeInitGantt初始之前准备过滤')
      this.getViewSize();
      this.tickNum = 0;
      this.filterData = this.ganttData;
      this.yScrollMin = 0;
      this.yScrollMax = this.maxY;
      let max = dayjs(this.scheduledInfo.planEndDate).valueOf();
      let min = dayjs(this.scheduledInfo.planStartDate).valueOf();

      let today = new Date().getTime();
      if (today > max || today < min) {
        this.$message.warning("当天不在此排程范围内！");
        this.xScrollMin = min;
        this.xScrollMax =min + 24 * 60 * 60 * 1000 * 2;
        this.isToday=false;
      }else{
        this.xScrollMin = new Date(new Date().toLocaleDateString()).getTime();
        this.xScrollMax =
          new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
        this.isToday=true;
      }

      // if(this.isFirst){
      //   this.xScrollMin = min;
      //   this.xScrollMax =min + 24 * 60 * 60 * 1000 * 2;
      // }else{
      //   this.isFirst=true;
      //   let today = new Date().getTime();
      //   if (today > max || today < min) {
      //     this.$message.warning("当天不在此排程范围内！");
      //     this.xScrollMin = min;
      //     this.xScrollMax =min + 24 * 60 * 60 * 1000 * 2;
      //   }else{
      //     this.xScrollMin = new Date(new Date().toLocaleDateString()).getTime();
      //     this.xScrollMax =
      //       new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
      //   }
      // }

      
      this.filterGantt();
      this.initDataCheck();
    },

    // resizeListen() {
    //   if (this.fclickThrottle()) {
    //     this.beforeInitGantt();
    //   } else {
    //     // console.log('过频')
    //   }
    // },

    //获取视图尺寸
    getViewSize() {
      let windowWidth = window.innerWidth;
      this.marginLeft = Math.round(windowWidth / 7);

      let ganttDiv =
        document.getElementsByClassName("ganttview-wrapper")[0].offsetHeight;
      this.maxY = Math.floor((ganttDiv - 60) / 51) - 1;
      // console.log('viewHeight',ganttDiv,(ganttDiv-60)/51);
    },
    initDataCheck() {
      // console.log('初始化之前过滤数据',this.curData[0].y)
      if (
        this.curData.length == 0 ||
        (this.curData.length == 1 && this.curData[0].y == this.columnTexts.length)
      ) {
        // console.log("agin-initDataCheck",this.curData.length,this.curData[0].y,this.columnTexts.length);

        //如果当天存在于排程范围内，先过滤y
        if(this.isToday){
          if(this.yScrollMax<this.columnTexts.length){
            this.yScrollMin+=this.maxY+0.1;this.yScrollMax+=this.maxY+0.1;
          }else{
            this.yScrollMin = 0;
            this.yScrollMax = this.maxY;
            let min = dayjs(this.scheduledInfo.planStartDate).valueOf();
            this.xScrollMin = min;
            this.xScrollMax =min + 24 * 60 * 60 * 1000 * 2;
            this.isToday=false
          }
        }else{
          if (this.xScrollMax == dayjs(this.scheduledInfo.planEndDate).valueOf()) {
            this.yScrollMin+=this.maxY+0.1;this.yScrollMax+=this.maxY+0.1;
          } else if (
            this.xScrollMax + 24 * 60 * 60 * 1000 * 2 >
            dayjs(this.scheduledInfo.planEndDate).valueOf()
          ) {
            this.xScrollMax = dayjs(this.scheduledInfo.planEndDate).valueOf();
          } else {
            this.xScrollMax += 24 * 60 * 60 * 1000 * 2;
          }
        }
        
        this.filterGantt();
        this.initDataCheck();
        //过滤X轴
        // if (this.xScrollMax == dayjs(this.scheduledInfo.planEndDate).valueOf()) {
        //   //过滤y轴
        //   this.yScrollMin+=this.maxY+0.1;this.yScrollMax+=this.maxY+0.1;
        //   this.filterGantt();
        //   console.log("initDataCheck111",this.curData.length,this.curData[0].y,this.columnTexts.length);
        //   this.initDataCheck();
        //   return;
        // } else if (
        //   this.xScrollMax + 24 * 60 * 60 * 1000 * 2 >
        //   dayjs(this.scheduledInfo.planEndDate).valueOf()
        // ) {
        //   this.xScrollMax = dayjs(this.scheduledInfo.planEndDate).valueOf();
        // } else {
        //   this.xScrollMax += 24 * 60 * 60 * 1000 * 2;
        // }


        // this.filterGantt();
        // console.log("initDataCheck222");
        // this.initDataCheck();
        // return;
      }else{
        this.initGantt();
      }
      
    },
    //过滤数据
    filterGantt() {
      // console.log('过滤数据')
      let arrY = this.filterData.filter((item) => {
        return (
          (item.y >= this.yScrollMin && item.y <= this.yScrollMax) ||
          item.y == this.columnTexts.length
        );
      });
      // console.log('过滤数据Y',arrY)
      let arrXY = arrY.filter((item) => {
        return (
          (item.start > this.xScrollMin && item.start < this.xScrollMax) ||
          (item.end > this.xScrollMin && item.end < this.xScrollMax) ||
          (item.start<=this.xScrollMin && item.end>=this.xScrollMax) ||
          item.y == this.columnTexts.length
        );
      });
      this.curData = arrXY;
      // console.log('过滤数据xY',this.curData)
    },

    initGantt() {
      console.log('初始化gantt')
      let _this = this;
      // 全局配置，需要在图标初始化之前配置
      this.gantt = Highcharts.setOptions({
        // autoCalculateParentTaskSum: true,
        global: { useUTC: false }, // 不使用utc时间
      });
      //初始化甘特图
      try {
        this.gantt = Highcharts.ganttChart("container", {
          accessibility: { enabled: false },
          series: [{ type: "gantt", data: this.curData }], //editable:{updateTime: false,changeDuration: false,}
          chart: {
            type: "gantt",
            backgroundColor: "#232323",
            // marginLeft:270,
            marginLeft: this.marginLeft,
            gridLineWidth: 0,

            // scrollablePlotArea: {
            //   minHeight: 900,  // 设置最小高度
            //   scrollPositionX: 1,
            //   scrollPositionY: 0,
            //   scrollWheel: true // 启用滚轮控制
            // }

            events: {
              // 监听鼠标滚轮事件
              mousewheel: function(event) {
                console.log('mousewheel',event.target)
                // 获取图表对象和滚动条对象
                // var chart = this,
                //     scrollbar = chart.yAxis[0].scrollbar;

                // // 计算滚动条新的位置
                // var newScrollPosition = scrollbar.position + event.deltaY;

                // // 确保滚动位置不超出滚动条的范围
                // if (newScrollPosition < 0) {
                //     newScrollPosition = 0;
                // } else if (newScrollPosition > scrollbar.height - scrollbar.size) {
                //     newScrollPosition = scrollbar.height - scrollbar.size;
                // }

                // // 更新滚动条的位置
                // scrollbar.update(newScrollPosition);
                
                // // 阻止事件的默认行为
                // event.preventDefault();
              }
            }
          },
          xAxis: [
            {
              tickInterval: 1000 * 60 * 60 * 24, //天
              grid: { cellHeight: 9, borderColor: "#B9B9B9" },
              labels: { enabled: false },
              // max: dayjs(_this.scheduledInfo.planEndDate).valueOf(),
              // min: dayjs(_this.scheduledInfo.planStartDate).valueOf(),
              max:  _this.xScrollMax,
              min:  _this.xScrollMin,
              events: {
                afterSetExtremes: function (e) {
                  console.log('触发X')
                  // if(_this.draging){console.log('X导航触发',_this.tickNum);return}
                  
                  if (_this.tickNum < 1) {
                    _this.tickNum += 1;
                    return;
                  }
                  // console.log('X导航改变逻辑执行')
                  let { min, max } = e;
                  _this.xScrollMin = min;
                  _this.xScrollMax = max;
                  _this.xDataCheck();
                  if (!_this.draging) {
                    // console.log('X改变后过滤')
                    _this.filterGantt();
                    _this.updateGantt();
                  }

                  let xline = Array.from(
                    document.getElementsByClassName("highcharts-axis-line")
                  );
                  xline.length &&
                    xline.forEach((item) => {
                      item.style.stroke = "#343434";
                    });
                },
              },
              currentDateIndicator: {
                color: "#29DC71",
                zIndex: 4,
                width: 2,
                label: {
                  useHTML: true,
                  format: "%H:%M",
                  x: -25,
                  y: 0,
                  style: {
                    color: "#FFF",
                    fontSize: "16px",
                    backgroundColor: "#29DC71",
                    padding: "0px 6px",
                    borderRadius: "4px",
                  },
                },
              },
            },
            {
              tickInterval: 1000 * 60 * 60 * 24, //天
              grid: { borderWidth: 0, cellHeight: 30 },
            },
            {
              tickInterval: 1000 * 60 * 60 * 24 * 30, //月
              grid: { borderWidth: 0, cellHeight: 10 },
              labels: { y: 110 },
            },
          ],

          yAxis: {
            type: "category", //treegrid category // The types are 'linear', 'logarithmic' and 'datetime'
            // endOnTick:false,
            // uniqueNames: true,
            minorGridLineColor: "#343434",
            // categories: ['Tech', 'Marketing', 'Sales'],
            grid: {
              // enabled: false,//启用轴标签上的网格
              borderColor: "#343434",
              borderWidth: 1,
              columns: this.ganttColumns,
            },
            labels: {
              useHTML: true,
            },
            // min: 0,
            // max:
            //   this.columnTexts.length <= this.maxY
            //     ? this.columnTexts.length - 1
            //     : this.maxY,
            min: _this.yScrollMin,
            max: _this.columnTexts.length <= this.maxY
                ? _this.columnTexts.length - 1
                : _this.yScrollMax,
            scrollbar: {
              enabled: true,
              liveRedraw: true,
              height: 10,
              barBackgroundColor: "#4B4B4B", // 滚动条背景颜色
              barBorderRadius: 6, // 滚动条圆角大小
              barBorderWidth: 0, // 滚动条边框宽度
              buttonBackgroundColor: "#313131", // 滚动条按钮背景颜色
              buttonBorderWidth: 0, // 滚动条按钮边框宽度
              // buttonArrowColor: 'white', // 滚动条按钮箭头颜色
              buttonBorderRadius: 10, // 滚动条按钮圆角大小
              rifleColor: "#4B4B4B", // 滚动条中间小条的颜色
              trackBackgroundColor: "#313131", // 滚动条轨道背景颜色
              trackBorderRadius: 10, // 滚动条轨道圆角大小
              trackBorderWidth: 0, // 滚动条轨道边框宽度
              // trackBorderColor: '#ccc', // 滚动条轨道边框颜色
            },

            // minHeight:200,
            events: {
              afterSetExtremes: function (e) {
                // console.log('Y滚动触发',_this.tickNum)
                if (_this.tickNum ==1) {
                  _this.tickNum += 1;
                  return;
                }
                let { min, max } = e;
                min = Math.floor(min);
                max = Math.floor(max);
                if (min == _this.yScrollMin && max == _this.yScrollMax) {
                  return;
                }
                _this.yScrollMin = min;
                _this.yScrollMax = max;
                // console.log('Y改变后过滤')
                _this.filterGantt();
                _this.updateGantt();
                

                let xline = Array.from(
                  document.getElementsByClassName("highcharts-axis-line")
                );
                xline.length &&
                  xline.forEach((item) => {
                    item.style.stroke = "#343434";
                  });
                let line = Array.from(
                  document.getElementsByClassName("highcharts-grid-line")
                );
                line.length &&
                  line.forEach((item) => {
                    item.style.stroke = "#343434";
                  });
              },

            },
          },
          //导航器
          navigator: {
            xAxis:{
              max: dayjs(_this.scheduledInfo.planEndDate).valueOf(),
              min: dayjs(_this.scheduledInfo.planStartDate).valueOf(),
            },
            enabled: true,
            margin: 0,
            // bottom:400,
            // top:608,
            adaptToUpdatedData: false,
            height: 15,
            series: {
              visible: false // 隐藏导航器中的缩略图
            }
          },
          scrollbar: {
            enabled: true,
            height: 10,
            barBackgroundColor: "#4B4B4B", // 滚动条背景颜色
            barBorderRadius: 6, // 滚动条圆角大小
            barBorderWidth: 0, // 滚动条边框宽度
            buttonBackgroundColor: "#313131", // 滚动条按钮背景颜色
            buttonBorderWidth: 0, // 滚动条按钮边框宽度
            // buttonArrowColor: 'white', // 滚动条按钮箭头颜色
            buttonBorderRadius: 10, // 滚动条按钮圆角大小
            rifleColor: "#4B4B4B", // 滚动条中间小条的颜色
            trackBackgroundColor: "#313131", // 滚动条轨道背景颜色
            trackBorderRadius: 10, // 滚动条轨道圆角大小
            trackBorderWidth: 0, // 滚动条轨道边框宽度
            // trackBorderColor: '#ccc', // 滚动条轨道边框颜色
          },
          rangeSelector: {
            enabled: false,
            // selected: 0,
          },

          tooltip: {
            outside: true,
            positioner: function (labelWidth, labelHeight, point) {
              var chart = this.chart;
              var tooltipY;
              var tooltipX = point.plotX; // 使用默认横向位置
              tooltipX = Math.min(tooltipX, chart.plotLeft + chart.plotWidth - labelWidth - 10);
              tooltipX = Math.max(tooltipX, chart.plotLeft + 10);
              // 计算tooltip纵向位置
              if (point.plotY + labelHeight > chart.plotHeight) {
                // 如果tooltip底部超出容器边界，将其放置在数据点上方
                tooltipY = point.plotY - labelHeight + 50;
              } else {
                // 否则，将tooltip放置在数据点下方
                tooltipY = point.plotY + 100;
              }

              return {
                x: tooltipX,
                y: tooltipY
              };
            },
            backgroundColor: "#232323",
            borderColor: "rgba(0,0,0,0.5)",
            borderRadius: "8",
            // followPointer:true,
            hideDelay: 1,
            style: {
              color: "#ffffff",
              fontSize: "16px",
            },
            useHTML: true,
            formatter: function () {
              return `<div style='height:350px;line-height:34px;padding:0px 10px;'>
                        <div style='font-size: 24px;font-weight: bold;margin:10px 0px';>
                          ${this.point.partCode}
                        </div>
                        ${this.point.procedureNo == "工步编号"? 
                        "": "工步编号：" + this.point.procedureNo}<br/>
                        工单号： ${this.point.orderNo}<br/>
                        ${this.point.procedureNo == "工步编号"? 
                        "投产号："+ this.point.productionNo: "排产号："+ this.point.schedulingNo}<br/>
                        
                        计划生产数量： ${this.point.plannedQuantity}<br/>
                        已完成数量： ${this.point.completedQuantity == null? "0": this.point.completedQuantity}<br/>
                        计划开始时间：<span class="${_this.draging ? "timecolor" : ""}"> 
                          ${dayjs(this.point.start).format("YYYY-MM-DD HH:mm:ss")}</span><br/>
                        计划完成时间：${dayjs(this.point.end).format("YYYY-MM-DD HH:mm:ss")}<br/>
                        完成周期：${this.point.totalTimeStr}<br/>
                        工单需求日期：${this.point.orderCompletionDate}
                      </div>`;
            },
          },

          plotOptions: {
            colors: ["#fff"],
            // connectNulls:true,
            // gantt:{ editable:{ resize: false } },
            series: {
              states: {
                select: {
                  enabled: false, // 禁用选中样式
                },
              },
              animation: false, // Do not animate dependency connectors
              dragDrop: this.dragDrop,
              dataLabels: {
                enabled: true,
                format: "{point.name}",
                borderRadius: 10,
                style: {
                  cursor: "default",
                  pointerEvents: "none",
                },
              },
              allowPointSelect: true,
             
              point: {
                events: {
                  drag: _this.drag,
                  drop: this.drop,
                  click: function () {
                    _this.handleSelect(this.schedulingNo, this.productionNo);
                  },
                  // dragStart:this.dragStart,
                  // update:this.update,
                  // select:this.handleSelect,
                },
              },
              //  dataGrouping: { enabled: true }
            },
          },

          exporting: { enabled: false },
          credits: { enabled: false }, // 去掉右下角版权信息
        });
      } catch (error) {
        console.log("ganttInitError", error);
      }


      //设置导航条初始范围、日期显示
      _this.xDataCheck();
      // _this.gantt.xAxis[0].setExtremes(_this.xScrollMin, _this.xScrollMax);
      // console.log("55555")
      // _this.gantt.yAxis[0].setExtremes(_this.yScrollMin+0.2, _this.yScrollMax+0.2);
      _this.filterStyle();
      _this.ganttStyle();

      //滚轮控制横坐标
      // this.gantt.container.addEventListener("wheel", function (e) {
      //   // console.log('滚轮')
      //   if (_this.fclickThrottle() == false) {
      //     return;
      //   }
      //   // console.log('scroll',e.deltaY)

      //   var signData = 24 * 60 * 60 * 1000; //一天
      //   let { min, max } = _this.gantt.xAxis[0].getExtremes();
      //   if (max - min > 1000 * 60 * 60 * 24 * 22) {
      //     signData = 24 * 60 * 60 * 1000 * 7; //七天
      //   } else if (max - min <= 1000 * 60 * 60 * 24 * 2) {
      //     signData = 6 * 60 * 60 * 1000; //6小时
      //   }

      //   let startDate = dayjs(_this.scheduledInfo.planStartDate).valueOf();
      //   let endDate = dayjs(_this.scheduledInfo.planEndDate).valueOf();
      //   if (e.deltaY > 0) {
      //     // console.log('向下滚轮，导航放大，图表缩小');
      //     if (min == startDate && max == endDate) {
      //       return;
      //     }
      //     // console.log('big');
      //     if (min - signData > startDate) {
      //       min -= signData;
      //     } else {
      //       min = startDate;
      //     }
      //     if (max + signData < endDate) {
      //       max += signData;
      //     } else {
      //       max = endDate;
      //     }
      //   } else {
      //     // console.log('向上滚轮，导航缩小，图表放大');
      //     if (max - signData <= min) {
      //       return;
      //     }
      //     // console.log('small');
      //     max -= signData;
      //   }
      //   _this.gantt.xAxis[0].setExtremes(min, max);
      // });

      //滚轮控制纵向滚动
      this.gantt.container.addEventListener("wheel", function (e) {
        // console.log('wheel')
        if (_this.fclickThrottle(200) == false) {return }
        let { min, max } = _this.gantt.yAxis[0].getExtremes();
        // min = Math.floor(min);
        // max = Math.floor(max);
        // console.log(min, max);
        let rate=1.2;
        if (e.deltaY > 0) {
          if(Math.floor(max)>=_this.columnTexts.length){return}
          min+=rate;max+=rate;
        }else{
          if(Math.floor(min)<=0){return}
          min-=rate;max-=rate;
        }
        _this.gantt.yAxis[0].setExtremes(min, max);
        
        // _this.gantt.yAxis[0].update({
        //   min:min,max:max
        // })
      })
    },

    xDataCheck() {
      // console.log('检测X轴显示')
      let range = this.xScrollMax - this.xScrollMin;
      if (range > 1000 * 60 * 60 * 24 * 22) {
        // console.log('大于22天显示月份')
        this.gantt.xAxis[0].update({
          tickInterval: 1000 * 60 * 60 * 24 * 30, //月
        });
        this.gantt.xAxis[1].update({
          tickInterval: 1000 * 60 * 60 * 24 * 30, //月
        });
        this.gantt.xAxis[2].update({
          tickInterval: 1000 * 60 * 60 * 24 * 30 * 12, //年
        });
        this.curTickInterval=14;
      } else if (range <= 1000 * 60 * 60 * 24 * 2) {
        // console.log('小于2天显示小时')
        this.gantt.xAxis[0].update({
          tickInterval: 1000 * 60 * 60, //小时
        });
        this.gantt.xAxis[1].update({
          tickInterval: 1000 * 60 * 60, //小时
        });
        this.gantt.xAxis[2].update({
          tickInterval: 1000 * 60 * 60 * 24, //天
        });
        this.curTickInterval=1;
      } else {
        // console.log('显示天数')
        this.gantt.xAxis[0].update({
          tickInterval: 1000 * 60 * 60 * 24, //天
        });
        this.gantt.xAxis[1].update({
          tickInterval: 1000 * 60 * 60 * 24, //天
        });
        this.gantt.xAxis[2].update({
          tickInterval: 1000 * 60 * 60 * 24 * 30, //月
        });
        this.curTickInterval=7;
      }
    },

    //单机和双击
    handleSelect(s, p) {
      this.clickCount++;
      if (this.clickCount === 2) {
        // console.log('双击事件schedulingNo',s);
        this.$emit("selectTaskDetail", s);
        this.clickCount = 0;
      }
      setTimeout(() => {
        if (this.clickCount === 1) {
          // console.log('单机事件productionNo',p)
          this.$emit("selectProcedure", p);
          this.clickCount = 0;
        }
      }, 300);
    },

    //滤镜样式
    filterStyle() {
      // console.log('滤镜')
      let parentDiv = document.getElementById("filterStyle");
      parentDiv.innerHTML = "";
      for (let key in this.taskColor) {
        let div = document.createElement("div");
        div.innerHTML = `
                    <svg class="svgClass">
                        <filter id="inset-shadow-${key}">
                            <feOffset dx="0" dy="0"/>
                            <feGaussianBlur stdDeviation="8" result="offset-blur"/>
                            <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
                            <feFlood flood-color="${this.taskColor[key]}" flood-opacity=".3" result="color"/>
                            <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
                            <feComposite operator="over" in="shadow" in2="SourceGraphic"/> 
                        </filter>
                    </svg>`;
        parentDiv.appendChild(div);
      }
    },

    //任务/进度条样式
    ganttStyle() {
      // console.log("渲染样式",this.taskColor)
      for (let key in this.taskColor) {
        let lists = Array.from(document.getElementsByClassName(key));
        // console.log(lists)
        lists.length &&
          lists.forEach((item) => {
            item.style.stroke = this.taskColor[key];
            item.style["stroke-width"] = 1;
            item.style.fill = "#232323";
            item.style["filter"] =
              key == "parent" ? "" : `url(#inset-shadow-${key})`;
          });
      }
      let overlay = Array.from(
        document.getElementsByClassName("highcharts-partfill-overlay")
      );
      overlay.length && overlay.forEach((item) => {
          if (item.attributes.fill.value == "#232323") {
            item.style["filter"] = `url(#inset-shadow)`;
            item.style["stroke-width"] = 2;
          }
        });
      let line = Array.from(
        document.getElementsByClassName("highcharts-grid-line")
      );
      line.length && line.forEach((item) => {
        item.style.stroke = "#343434";
      });

      document.getElementsByClassName("highcharts-axis-line")[1].style.display = "none";
      document.getElementsByClassName("highcharts-axis-line")[3].style.stroke = "#343434";
      document.getElementsByClassName("highcharts-axis-line")[4].style.stroke = "#343434";

      // document.getElementsByClassName('highcharts-scrollbar-track')[0].style.width = 7;
      // document.getElementsByClassName('highcharts-scrollbar-thumb')[0].style.width = 7;
      // console.log('样式渲染结束')
    },

    //更新gantt数据
    updateGantt() {
      this.draging = false;
      if ( this.curData.length == 0 ||
        (this.curData.length == 1 && this.curData[0].y == this.columnTexts.length)
      ) {
        // this.ganttStyle();
        return;
      }
      this.gantt.update({
        series: [
          {
            data: this.curData,
          },
        ],
      });
      this.ganttStyle();
      // console.log('update',this.curData)
    },
    drag() {
      // console.log('drag~~~~~ing');
      this.draging = true;
      

    },
    dragStart(){
      // console.log('dragStart',this.xScrollMin, this.xScrollMax);
      // this.gantt.update({
      //   navigator: {
      //       enabled: false,
      //       scrollbar:false
      //   }
      // });
        // this.prevData=JSON.stringify(this.curData);
    },
    // update(e){
    //     console.log('update',e)
    // },
    // select(){
    //     console.log('select')
    // },

    drop(e) {
      let obj = e.target;
      let tempData = {
        serialNumber: this.tempSerialNum,
        schedulingNo: obj.schedulingNo,
        procedureStartTime: dayjs(obj.start).format("YYYY-MM-DD HH:mm:ss"),
        selectedDevices: this.columnTexts[obj.y],
        snapshotNumber: this.curStep,
      };
      // console.log('待验证数据',tempData);
      //拖拽验证
      this.$emit("handleDrop", tempData);
    },

    // 回到当前日期
    nowDate() {
      let max = dayjs(this.scheduledInfo.planEndDate).valueOf();
      let min = dayjs(this.scheduledInfo.planStartDate).valueOf();
      let today = new Date().getTime();
      if (today > max || today < min) {
        this.$message.warning("当天不在此排程范围内！");
        return;
      }
      let startTime = new Date(new Date().toLocaleDateString()).getTime();
      let endTime =
        new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000;
      this.gantt.xAxis[0].setExtremes(startTime, endTime);
    },

    //控制事件触发频率
    fclickThrottle(interval) {
      let now = +new Date();
      let timer = this.clickTimer;
      if (now - timer < interval) {
        return false;
      } else {
        this.clickTimer = now;
        return true;
      }
    },
  },
};
</script>
<style> 
.timecolor {
  color: #1bce62;
}

</style>
<style lang="scss" scoped>
#container {
  width: 100%;
}
.ganttview-wrapper {
  // width: 100vw;
  // overflow: hidden;
  position: fixed;
  top: 232px;
  bottom: 60px;
  right: 0px;
  left: 0px;
  .blackBorder {
    position: fixed;
    left: 262px;
    top: 134px;
    width: 4px;
    height: 50vh;
    background-color: #161616;
  }
  .blackBorder2 {
    position: fixed;
    bottom: 62px;
    left: 262px;
    width: 4px;
    height: 50vh;
    background-color: #161616;
  }
}
.svgClass {
  position: fixed;
  top: -200px;
  right: 200px;
}
// 滚动条
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background: #4b4b4b;
  border-radius: 4px;
}
</style>