<template>
  <div class="tip-wrapper">
    <el-dialog :visible="tipDialog" top="240px" :before-close="tipClose">
      <div class="tipText">{{tipText}}</div>
      <slot></slot>
      <div class="dialog-footer">
          <el-button @click="$emit('update:tipDialog',false)">取 消</el-button>
          <el-button type="primary" @click="$emit('confirm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{tipText:String,tipDialog:Boolean},
  data(){
    return{
      // tipDialog:true
    }
  },
  methods:{
    tipClose(){
      this.$emit('update:tipDialog',false)
    }
  }

}
</script>

<style lang="scss" scoped>
.tip-wrapper{
  .tipText{
    width: 220px;
    font-size: 20px;
    padding-top: 40px;
    margin: 0 auto;
    // height: 70px;
    // line-height: 70px;
  }
  ::v-deep .el-dialog{
        width: 350px;
        // height: 200px;
        background: #232323;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
            font-size: 30px;
        }
        .el-dialog__header{
            padding: 0px;
        }
        .el-dialog__body{
            padding: 0px;
        }
  }
  .dialog-footer{
    width: 220px;
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    padding-bottom: 30px;
  }
  .el-button:first-child{
      width: 90px;
      height: 40px;
      background: rgba(20, 20, 20, 0);
      border: 1px solid rgba(255, 255, 255, 0.2);
      opacity: 1;
      border-radius: 6px;
  }
  .el-button:last-child{
      width: 90px;
      height: 40px;
      background: #1BCE62;
      opacity: 1;
      border-radius: 6px;
      font-size: 16px;
      font-family: HarmonyOS;
      font-weight: 400;
      color: #FFFFFF;
  }
}

</style>