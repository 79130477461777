<template>
  <div class="dialog-reset">
    <el-dialog 
      title="导入数据"
      custom-class="dialog-custom"
      :visible="visible" 
      :before-close="handleClose" 
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <el-upload
        class="upload-data"
        drag
        action="#"
        accept=".xlsx"
        :show-file-list="false"
        :on-change="handleChange"
        :http-request = "handleUpload"
        >
        <img src="@/assets/images/common/import.png">
        <div class="el-upload__text">点击或者拖动文件到虚线框内上传</div>
        <div class="el-upload__tip" slot="tip">
          为了保证数据导入顺利，请您下载使用
          <span class="loadTemplate" @click="loadTemplate">下载模板</span>。
        </div>
      </el-upload>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import {downImportTemplete} from "@/api/gantt.js";
import dayjs from 'dayjs'
export default {
  
 name: 'import-dialog',
 props:{
  visible:{
    required:false,
    type:Boolean
  },
  // 模板名称
  templateName:{
    default:'',
    type:String
  },
  templateParam:String,
  apis:Object,
 },
 components:{
  
 },
  data() {
    return {
     
    }
  },
  methods: {
    handleClose(){
      this.$emit('update:visible',false)
    },
    // 导入Excel
    handleChange(file){
      let formData = new FormData();
      formData.append('file',file.raw);
      let Api =this.apis.import;
      Api && Api(formData).then(res=>{
        this.$emit('update:visible',false)
        if(res.code == 0){
          this.$message.success('导入成功！')
          this.$emit('success')
        }else{
          this.$message.warning(res.msg)
        }
      }).catch((error)=>{
        this.$message.warning(error)
      })
    },
    handleUpload(){
      
    },
    // 下载模板
    loadTemplate(){
      let Api =downImportTemplete;
      Api && Api(this.templateParam).then(res=>{
        let blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
        })
        let a = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = (this.templateName || this.templateParam+'-数据模板')+dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
        // 将a标签添加到body中是为了更好的兼容性，谷歌浏览器可以不用添加
        document.body.appendChild(a)
        a.style.display = 'none'
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
        
      })
    },
  }
}
</script>

<style lang="scss" scoped>
// @import url('./style/index.scss');
::v-deep .el-dialog {
  width: 654px;
  height: 364px;
  font-family: HarmonyOS, PingFang SC;
  box-sizing: border-box;
  padding: 0px 10px;
  z-index: 999;
  .el-dialog__body{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .el-dialog__title{
    font-size: 20px;
    font-weight: bold;
  }
}
.loadTemplate{
  cursor: pointer;
  color: #1677FF;
}
.dialog-reset{
  text-align: left;
  .upload-data{
    ::v-deep .el-upload-dragger{
      background: #343434;
      border-color: #656565;
      width: 590px;
      img{
        width: 36px;
        height: 36px;
        margin-top: 50px;
      }
      .el-upload__text{
        color: #B9B9B9;
        font-size: 18px;
      }
    }
    .el-upload__tip{
      font-size: 16px;
      color: #B9B9B9;
    }
  }
}
.dialog-custom{
  width: 650px;
  background: #262626;
  box-shadow: 0px 10px 30px 1px rgba(0,0,0,0.82);
  border-radius: 12px;
  .el-dialog__header{
    padding-left:30px ;
    .el-dialog__title{
      font-size: 20px;
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: #8C8C8C;
      font-size: 16px;
    }
  }
  .el-dialog__body{
    padding: 0px 30px;
    .el-input__inner {
      border: 1px solid #424242;
      border-radius: 6px;
    }
    .el-checkbox__inner {
      border: 1px solid #4f4f4f!important;
    }
  }
  .dialog-footer{
    .el-button{
      height: 32px;
      width: 65px;
      padding: 0;
      text-align: center;
      border-radius: 6px;
      line-height: 32px;
    }
    .el-button--default{
      color: #B9B9B9;
      border: 1px solid #656565;
    }
    .el-button--primary{
      color: #FFFFFF;
      border: none;
    }
  }
}
.el-drawer{
  background: #2D2D2D;
  box-shadow: -5px 0px 20px 1px rgba(0,0,0,0.34);
  border-radius: 6px;
  width: 576px;
  text-align: left;
  padding: 30px 35px;
}
.el-drawer.rtl{
  top: 64px !important;
}
</style>
