<template>
<div>
    <div class="deviceOperation">
        <div class="inUse">{{curStep==1&&scheduledInfo.scheduledStatus!=0? scheduledInfo.serialNumber:''}} 
            <div :class="curStep==1?this.typeClass:'whiteColor'">
            <span></span> {{curStep==1?ganttType[scheduledInfo.scheduledStatus]:'待保存' }} </div>
        </div>
        <div class="picker">
            <div><i class="el-icon-arrow-left"></i></div>
            <div>{{scheduledInfo.planStartDate}}</div> 
            <span>至</span>
            <div>{{scheduledInfo.planEndDate}}</div>
            <div><i class="el-icon-arrow-right"></i></div>
        </div>

        <div class="searchDiv">
            <el-input 
            v-model="searchValue" 
            placeholder="投产号、订单号、图号……" 
            @keyup.enter.native="handleSearch"/>
            <i @click="handleSearch" class="el-icon-search"></i>
        </div>

        <div class="iconOption" v-if="!craft">
            <el-tooltip class="item" effect="dark" content="撤销" placement="top">
                <div :class="curStep<=1? 'opcity':''" @click="$emit('stepChange', {step:curStep-1,undoOrNext:'undo'})">
                    <img src="@/assets/images/gantt/back.png">
                </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="恢复" placement="top">
                <div :class="curStep==stepLength? 'opcity':''" @click="$emit('stepChange',{step:curStep+1,undoOrNext:'next'})">
                    <img src="@/assets/images/gantt/next.png">
                </div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="保存" placement="top">
                <div @click="$emit('btnGantt',1)"><img src="@/assets/images/gantt/save.png"></div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="刷新" placement="top">
                <div @click="$emit('renewGantt')"><img src="@/assets/images/gantt/refresh.png"></div>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
                <div @click="$emit('btnGantt',2)"><img src="@/assets/images/gantt/del.png"></div>
            </el-tooltip>
        </div>
        <div v-if="craft" class="craftbtn" @click="$emit('nowDate')">当天</div>
        <div class="buttonOption" v-if="!craft">
            <div class="btn" @click="$emit('nowDate')">当天</div>
            <div v-premiSub="'MES同步'" class="btn" @click="$emit('mesSync')">MES 同步</div>
            <div v-premiSub="'导入在制品'" class="btn" @click="$emit('importSync')">导入在制品</div>
            <el-select v-model="sortMethod">
                <el-option
                    v-for="item in sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button @click="handlesCheduling" v-premiSub="'排程操作'">
                <img src="@/assets/images/gantt/crontab.png"> 排程
            </el-button>

        </div>
    </div>

    <div class="schedulingDialog">
        <el-dialog :visible.sync="schedulingDialog">
            <div class="radioDiv">
                <div class="pcTitle">排程</div>
                <div class="labelTitle">优先规则</div>
                <el-radio-group v-model="mode">
                    <el-radio v-for="item in modeOptions" :key="item.value" :label="item.value">{{item.label}}</el-radio>
                </el-radio-group>
                <div class="labelTitle">类型选择</div>
                <el-radio-group v-model="typeRadio">
                    <el-radio :label="1">正式</el-radio>
                    <!-- <el-radio :label="2">意向</el-radio> -->
                </el-radio-group>
                <div class="labelTitle">算法选择</div>
                <el-radio-group v-model="curSchedulingMethod" class="algoDiv">
                    <el-radio v-for="(item,index) in schedulingMethods" :key="index" :label="item">{{item}}</el-radio>
                </el-radio-group>
                <div class="labelTitle">排程开始日期</div>
                <el-date-picker 
                    v-model="scheduleStartDate" 
                    type="datetime" 
                    value-format="yyyy-MM-dd HH:mm:ss" 
                    prefix-icon="el-icon-date" 
                    :picker-options="pickerOptions"
                    default-time="00:00:00">
                </el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="schedulingDialog = false">取 消</el-button>
                <el-button type="primary" @click="schedulingSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</div>
</template>

<script>
import dayjs from 'dayjs';
import {mapState} from "vuex";
export default {
    props:{
        stepLength:{type:Number,default:0},
        curStep:{type:Number,default:0},
        scheduledInfo:{},
        craft:Boolean
    },
    data(){
        return{
            sortOptions: [
                {value: 1,label: '正向排序'}, 
                // {value: 2,label: '逆向排序'},
                // {value: 3,label: '双向排序'}
            ],
            modeOptions: [
                {value: 1,label: '交期优先 '}, 
                {value: 2,label: '客户优先 '},
                {value: 3,label: '订单优先'},
                // {value: 4,label: '设备利用率优先'},
            ],
            algorithm: [
                {value: 1,label: 'FTA'}, 
                {value: 2,label: 'EE'},
                {value: 3,label: 'ES'},
            ],
            ganttType:{
                0:'待保存',
                1:'未发布',
                2:'使用中',
                3:'已发布',
            },
            searchValue:'',
            sortMethod:1,
            mode:1,
            typeRadio:1,
            scheduleStartDate:'',
            curSchedulingMethod:'FTA',

            schedulingDialog:false,
            importVisible:false,

            pickerOptions:{
                disabledDate(time){
                    return time.getTime()<Date.now()-8.64e7;
                },
                selectableRange:`00:00:00 - 23:59:59`,
            }

        }
    },
    computed:{
        ...mapState(['isSupportMes']),
        typeClass(){
            switch (this.scheduledInfo.scheduledStatus){
                case 1: return 'yellowColor'
                case 2: return 'greenColor'
                case 3: return 'blueColor'
                default: return 'whiteColor'
            }
        },
        schedulingMethods(){
            return["FTA", "EE", "ES"]
        }

    },
    watch:{
        scheduleStartDate(){
            if(dayjs(this.scheduleStartDate).valueOf()<=Date.now()){
                this.scheduleStartDate=dayjs(Date.now()).format("YYYY-MM-DD HH:mm:ss");
                this.pickerOptions.selectableRange =`${Date().split(" ")[4]} - 23:59:59`;
                // console.log('jt1',this.scheduleStartDate);
            }else{
                this.pickerOptions.selectableRange =`00:00:00 - 23:59:59`;
                // console.log('jt2',this.scheduleStartDate);
            }
            
        }
    },
    methods:{
        //排程
        handlesCheduling(){
            if(!this.$parent.isShowGantt || 
                this.curStep==1&&this.scheduledInfo.scheduledStatus!=0){
                this.schedulingDialog=true;
                this.typeRadio=1;
                this.mode=1;
            }else{
                this.$message.warning('当前有更新待保存，请先保存或清空！');
            }
            
        },
        schedulingSubmit(){
            let obj={
                sortMethod:this.sortMethod,
                mode:this.mode,
                typeRadio:this.typeRadio,
                curSchedulingMethod:this.curSchedulingMethod,
                scheduleStartDate:this.scheduleStartDate
            };
            this.$emit('schedulingSubmit',obj);
            this.schedulingDialog=false;
        },
        //搜索
        handleSearch(){
            this.$emit('handleSearch',this.searchValue);
        },

    }

}
</script>

<style  lang="scss">
//日期组件
//选中项日期高亮（写全局）
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div{
  background-color: #A6A6A6;
}

</style>
<style lang="scss" scoped>
@import "../styles/operation.scss";

</style>